import "./styles.scss";
import moment from "moment";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import toHoursAndMinutes from "libs/helpers/toHoursAndMinutes";
import { useApp } from "context/app";

const mainClass = "task-conversation__details__list";

const List = ({
  data,
  setWebsiteInfoModal,
  admin_mode,
  show_time_entries,
  only_time_tracked,
}) => {
  const { t } = useTranslation();

  const { user } = useApp();

  const title_data = [
    {
      label: t("Website"),
      value: data?.website,
      onclick: !!setWebsiteInfoModal
        ? () => setWebsiteInfoModal(data?.website_id)
        : null,
    },
  ];

  const details_data = [
    {
      label: t("Task title"),
      value: data?.title,
      highlighted: data?.last_updated?.includes("title"),
    },
    {
      label: t("Client message"),
      value: (
        <div dangerouslySetInnerHTML={{ __html: data?.messages?.[0]?.text }} />
      ),
      highlighted: data?.last_updated?.includes("finished_when"),
    },
  ];

  const date_details_data = [
    {
      label: t("Estimated time"),
      value: !!data?.estimated_time?.$numberDecimal
        ? toHoursAndMinutes(data?.estimated_time?.$numberDecimal)?.formatted
        : null,
      highlighted: data?.last_updated?.includes("estimated_time"),
    },
    {
      label: t("Due date"),
      value: data?.due_date ? moment(data?.due_date)?.format("ll") : null,
      highlighted: data?.last_updated?.includes("due_date"),
    },
  ];

  let time_over = data?.time_over;
  let plan_time = data?.total_time - time_over;

  let accumulatedTime = 0;

  const formatted_entries = data?.time_entries?.map((item) => {
    const time = parseFloat(item?.time?.$numberDecimal);
    let total_over = time_over - time;

    accumulatedTime = accumulatedTime + time;

    if (accumulatedTime > plan_time) {
      total_over = accumulatedTime - plan_time;
      accumulatedTime = accumulatedTime - total_over;
    } else {
      total_over = 0;
    }

    return {
      ...item,
      time_over: total_over,
    };
  });

  const total_time_data = [
    {
      label: t("Total time"),
      value: !!data?.task_total_time
        ? toHoursAndMinutes(data?.task_total_time)?.formatted
        : null,
    },
    {
      key: "time_exceeding",
      label: t("Time exceeding plan"),
      value: time_over > 0 ? toHoursAndMinutes(time_over)?.formatted : null,
    },
    // {
    //   key: "time_exceeding",
    //   label: t("Time exceeding plan"),
    //   value: time_over > 0 ? toHoursAndMinutes(time_over)?.formatted : null,
    // },
  ];

  const time_tracked_data = [
    {
      label: `${t("Time tracked")}:`,
      items: formatted_entries,
    },
  ];

  return (
    <div className={mainClass}>
      {!!!only_time_tracked &&
        title_data?.map(({ label, value, onclick, highlighted }, index) => {
          if (!!!value) return null;

          return (
            <div
              key={index}
              className={classnames(`${mainClass}__item`, {
                [`${mainClass}__item--link`]: !!onclick,
                [`${mainClass}__item--highlighted`]:
                  user?.type === "client" &&
                  !!highlighted &&
                  data?.status === 2,
              })}
            >
              <strong>{label}</strong>
              <span onClick={onclick}>{value}</span>
            </div>
          );
        })}
      {!!!only_time_tracked && (
        <div
          className={classnames(`${mainClass}__item__wrapper`, {
            [`${mainClass}__item__wrapper--highlighted`]:
              user?.type === "client" && data?.status === 2,
          })}
        >
          {details_data?.map(({ label, value, onclick }, index) => {
            if (!!!value) return null;

            return (
              <div
                key={index}
                className={classnames(`${mainClass}__item`, {
                  [`${mainClass}__item--link`]: !!onclick,
                })}
              >
                <strong>{label}</strong>
                <span onClick={onclick}>{value}</span>
              </div>
            );
          })}
          {(!!data?.estimated_time?.from || !!data?.due_date) && (
            <div className={`${mainClass}__item__group`}>
              {date_details_data?.map(({ label, value }, index) => {
                if (!!!value) return null;

                return (
                  <div key={index} className={`${mainClass}__item`}>
                    <strong>{label}</strong>
                    <span>{value}</span>
                  </div>
                );
              })}
            </div>
          )}

          <div className={`${mainClass}__item__group`}>
            {total_time_data?.map(({ key, label, value }, index) => {
              if (!!!value) return null;

              return (
                <div
                  key={index}
                  className={classnames(`${mainClass}__item`, {
                    [`${mainClass}__item--error`]: key === "time_exceeding",
                  })}
                >
                  <strong>{label}</strong>
                  <span>{value}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {!!show_time_entries &&
        data?.time_entries?.length > 0 &&
        time_tracked_data?.map(({ label, items }, index) => {
          if (!!!items) return null;

          return (
            <div
              key={index}
              className={`${mainClass}__item ${mainClass}__item--entries`}
            >
              <strong>{label}</strong>

              <div key={index} className={`${mainClass}__item__list`}>
                {items
                  ?.map((item, index) => (
                    <div
                      key={index}
                      className={`${mainClass}__item__list__entry ${
                        !!item?.time_over
                          ? `${mainClass}__item__list__entry--over`
                          : ""
                      }`}
                    >
                      {`${
                        toHoursAndMinutes(
                          parseFloat(item?.time?.$numberDecimal)
                        )?.formatted
                      } ${t("on")} ${moment(item?.created_at)?.format("ll")}`}
                      <span> {t("by")} </span>
                      {!!admin_mode ? (
                        <Link
                          to={`/webmasters/${item?.webmaster?._id}`}
                        >{`${item?.webmaster?.first_name} ${item?.webmaster?.last_name}`}</Link>
                      ) : (
                        <span>{`${item?.webmaster?.first_name} ${item?.webmaster?.last_name}`}</span>
                      )}
                    </div>
                  ))
                  ?.reverse()}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default List;
