import { ReactComponent as BasicIcon } from "assets/icons/basic-plan.svg";
import { ReactComponent as PremiumIcon } from "assets/icons/premium-plan.svg";
import { ReactComponent as EcommerceIcon } from "assets/icons/ecommerce-plan.svg";
import { ReactComponent as LaptopIcon } from "assets/icons/laptop.svg";
import { ReactComponent as BackupIcon } from "assets/icons/backup.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";

const plans = [
  {
    icon: <BasicIcon />,
    description: "Security and ongoing updates",
    features: [
      {
        icon: <LaptopIcon />,
        label: "Web development",
        value: null,
        hint: "We give you a number of hours per month in our subscriptions to use for web development. Any larger-scale projects that would exceed the hours given to you in the subscriptions are billed on an hourly basis, after our estimation and your acceptance.",
      },
      {
        icon: <BackupIcon />,
        label: "Backup",
        value: "4x",
        hint: "We regularly create backups of your website stored on protected cloud storage (both files and database) in order to keep it safe and easily recoverable at all times.",
      },
      {
        icon: <SettingsIcon />,
        label: "Updates",
        value: "1x",
        hint: "We will have your Content Management System like WordPress and WooCommerce, along with all the plugins, regularly updated to the newest version.",
      },
    ],
  },
  {
    icon: <PremiumIcon />,
    description:
      "For websites where content changes infrequently (1-2 times per month)",
    features: [
      {
        icon: <LaptopIcon />,
        label: "Web development",
        value: "1h",
        hint: "We give you a number of hours per month in our subscriptions to use for web development. Any larger-scale projects that would exceed the hours given to you in the subscriptions are billed on an hourly basis, after our estimation and your acceptance.",
      },
      {
        icon: <BackupIcon />,
        label: "Backup",
        value: "4x",
        hint: "We regularly create backups of your website stored on protected cloud storage (both files and database) in order to keep it safe and easily recoverable at all times.",
      },
      {
        icon: <SettingsIcon />,
        label: "Updates",
        value: "2x",
        hint: "We will have your Content Management System like WordPress and WooCommerce, along with all the plugins, regularly updated to the newest version.",
      },
    ],
  },
  {
    icon: <EcommerceIcon />,
    description:
      "For websites and WooCommerce stores where content changes frequently",
    features: [
      {
        icon: <LaptopIcon />,
        label: "Web development",
        value: "3h",
        hint: "We give you a number of hours per month in our subscriptions to use for web development. Any larger-scale projects that would exceed the hours given to you in the subscriptions are billed on an hourly basis, after our estimation and your acceptance.",
      },
      {
        icon: <BackupIcon />,
        label: "Backup",
        value: "8x",
        hint: "We regularly create backups of your website stored on protected cloud storage (both files and database) in order to keep it safe and easily recoverable at all times.",
      },
      {
        icon: <SettingsIcon />,
        label: "Updates",
        value: "4x",
        hint: "We will have your Content Management System like WordPress and WooCommerce, along with all the plugins, regularly updated to the newest version.",
      },
    ],
  },
];

export default plans;
